



































































































































































































import {
    Component,
    Vue
} from "vue-property-decorator";
import {
    Dialog,
    Toast
} from "vant";
import Mixin from "@/js/mixins";

@Component({
    mixins: [Mixin]
})
export default class HomeAgent extends Vue {
    public user_id: any = "";
    public info = {}; // 用户信息

    public isShowAll = false; //是否显示全部
    showAll() {
        this.isShowAll = !this.isShowAll
        console.log(this.isShowAll)
    }
    // 显示推荐码
    showParentName() {
        let user: any = this.info;
        Dialog.confirm({
            title: "推荐码",
            message: "您的推荐码为： " + user.user_name,
            confirmButtonText: "复制推荐码"
        }).then(() => {
            let copy: any = this.$refs.copy;
            copy.click();
        });
    }
    copy() {
        Toast("复制成功");
    }
    // 获取用户信息
    getInfo() {
        let _this = this;
        this.$api.request({
            url: "salesman/user/info",
            data: {
                user_id: this.user_id
            },
            success(res) {
                _this.info = res.data
                console.log(_this.info)
            }
        });
    }

    init() {
        this.user_id = this.$route.query.id
        this.getInfo();
    }
}
